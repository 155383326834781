
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { isPhone } from '@/helpers/validations';
  import FormModal from '@/mixins/formModal';
  import RegionsFilter from '@/components/filters/Resources.vue';
  import CheckAccess from '@/components/CheckAccess.vue';

  @Component({ components: { RegionsFilter, CheckAccess } })
  export default class LocationModal extends Mixins(FormModal) {
    isPhone(value: string): boolean {
      if (!value) return true
      return isPhone(value);
    }
  }
