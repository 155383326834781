
  import { Component, Prop } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import FormModal from '@/mixins/formModal';
  import { LocationDeliveryType, LocationDelivery, TextValuePair } from '@/interfaces';

  const appStore = namespace('app');

  @Component
  export default class LocationDeliveryModal extends FormModal {
    @Prop() declare readonly item: LocationDelivery[]

    @appStore.State('providersList')
    readonly provides!: string[];

    get itemDeliveryTypes(): Record<string, LocationDeliveryType> {
      return this.item && this.item.reduce((all, curr) => ({
        ...all,
        [curr.source]: curr.deliveryType
      }), {}) || {};
    }

    get deliveryTypes(): TextValuePair[] {
      return Object.keys(LocationDeliveryType).map(item => (
        { value: item as LocationDeliveryType, text: this.$t(`locations.couriers.${ item }`) as string }
      ));
    }

    itemChanged(source: string, deliveryType: LocationDeliveryType): void {
      const item = { source, deliveryType };
      const ndx = this.data.findIndex((_item: LocationDelivery) => _item.source === item.source)
      
      if (ndx !== -1) {
        this.data[ndx] = item;
      } else {
        this.data.push(item);
      }
  }
}
