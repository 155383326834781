
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DataTableHeader } from 'vuetify';
import { watch } from 'vue';

import { Location, LocationDelivery, Resource, ResourceType } from '@/interfaces';
import { UpdateDeliveryPayload, QueryLocations } from '@/store/interfaces';
import { getCitiesFromCountries } from '@/helpers/locations';
import { filter } from '@/helpers/filter';
import LocationModal from '@/components/modals/Location.vue';
import LocationDeliveryModal from '@/components/modals/LocationDelivery.vue';
import CountriesFilter from '@/components/filters/Countries.vue';
import CheckAccess from '@/components/CheckAccess.vue';
import DotIndicator from '@/components/DotIndicator.vue';
import router from '../router';

const locationsStore = namespace('locations');
const resourcesStore = namespace('resources');

@Component({
  components: { DotIndicator, CountriesFilter, LocationModal, LocationDeliveryModal, CheckAccess },
})
export default class LocationsPage extends Vue {
  showModal = false;
  showEditDeliveryModal = false;
  itemToEdit?: Location;
  countryFilter: string[] = [];
  cityFilter: string[] = [];
  optionFilter: string[] = [];
  selectedFilterOption: string[] = [];
  selectedRegion: number[] | null = null;

  @resourcesStore.State
  readonly resources!: Resource[];

  mounted(): void {
    this.getResources('region')
    this.updateSelectedFilterOption();
    if (this.$route.query?.closed) {
      this.getLocations({ closed: this.$route.query?.closed === 'true' });
    } else {
      this.getLocations();
    }
    watch(
      () => ({filter: this.selectedFilterOption, regions: this.selectedRegion}),
      ({filter, regions}) => {
        const filterBoolean = filter.includes('closed')
        const filterObject: any = {}
        if (filterBoolean) {
          filterObject.closed = filterBoolean
        }

        if (regions) {
          filterObject.regions = regions
        }
        this.getLocations(filterObject)
        router.replace({ query: { closed: String(filterBoolean) } });
      }
    );
  }

  @locationsStore.State
  readonly locations!: Location[];

  @resourcesStore.Action
  getResources!: (type: ResourceType) => Resource[];

  updateSelectedFilterOption(): void {
    const closedQueryParam = this.$route.query.closed;

    if (closedQueryParam === 'true') {
      this.selectedFilterOption = ['closed'];
    } else {
      this.selectedFilterOption = [];
    }
  }
  
  get headers(): DataTableHeader[] {
    return [
      { value: 'id', text: this.$t('locations.id') as string },
      { value: 'name', text: this.$t('locations.name') as string },
      { value: 'city', text: this.$t('locations.city') as string },
      { value: 'ops', text: '', align: 'end' },
    ];
  }

  get cities(): string[] {
    return getCitiesFromCountries(this.locations, this.countryFilter);
  }

  get filterOptions(): { value: string; text: string }[] {
    return [{ value: 'closed', text: this.$t('locations.filterClosed') as string }];
  }

  get filteredLocations(): Location[] {
    return filter(this.locations, {
      country: this.countryFilter,
      city: this.cityFilter,
    });
  }

  @locationsStore.Action
  getLocations!: (filter?: QueryLocations) => Promise<Location[]>;

  @locationsStore.Action
  removeLocation!: (id: number) => Promise<void>;

  @locationsStore.Action
  updateLocation!: (data: Partial<Location>) => Promise<void>;

  @locationsStore.Action
  addLocation!: (data: Partial<Location>) => Promise<void>;

  @locationsStore.Action
  updateDelivery!: (data: UpdateDeliveryPayload) => void;

  add(): void {
    this.itemToEdit = undefined;
    this.showModal = true;
  }

  edit(item: Location): void {
    this.itemToEdit = item;
    this.showModal = true;
  }

  editDelivery(item: Location): void {
    this.itemToEdit = item;
    this.showEditDeliveryModal = true;
  }

  submit(data: Partial<Location>): void {
    data.id ? this.updateLocation(data) : this.addLocation(data);
  }

  remove(id: number): void {
    if (confirm(this.$t('locations.removeConfirm') as string)) {
      this.removeLocation(id);
    }
  }

  updateDeliveryData(data: LocationDelivery[]): void {
    this.itemToEdit && this.updateDelivery({ locationId: this.itemToEdit.id, data });
  }
}
