<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          :color="dotColor"
          class="small-dot"
          v-bind="attrs"
          v-on="on"
          size="12"
      >
        mdi-circle
      </v-icon>
    </template>
    <span class="smaller-tooltip">{{ tooltipText }}</span>
  </v-tooltip>
</template>


<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    tooltipText: {
      type: String,
      default: 'This is a activity indicator'
    }
  },
  computed: {
    dotColor() {
      return this.isActive ? 'green' : 'red';
    }
  }
}
</script>

<style scoped>
.smaller-tooltip {
  font-size: 0.75rem;
}
.v-tooltip__content {
  padding: 2px 4px !important;
}
</style>
