interface ModelWithCountry {
  country: string;
}

interface ModelWithCountryAndCity extends ModelWithCountry {
  city: string;
}

export function getCitiesFromCountries(models: ModelWithCountryAndCity[], countries: string[]): string[] {
  if (!countries.length) {
    return [];
  }

  return models
    .filter(model => countries.includes(model.country))
    .map(model => model.city)
    .filter((item, ndx, arr) => arr.indexOf(item) === ndx)
    .sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);
}
